import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про Нас | Remartastyle Sport Pub
			</title>
			<meta name={"description"} content={"Історія Remartastyle Sport Pub - це історія створення особливого закладу, який пропонує відмінний смак та атмосферу затишку. Ми завжди прагнули бути місцем"} />
			<meta property={"og:title"} content={"Про Нас | Remartastyle Sport Pub"} />
			<meta property={"og:description"} content={"Історія Remartastyle Sport Pub - це історія створення особливого закладу, який пропонує відмінний смак та атмосферу затишку. Ми завжди прагнули бути місцем"} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Про Нас
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Історія Remartastyle Sport Pub - це історія створення особливого закладу, який пропонує відмінний смак та атмосферу затишку. Ми завжди прагнули бути місцем, де кожен гість може насолодитися справжнім задоволенням і вибором. Наша команда старається робити ваше перебування особливим, надаючи цінні бонуси та пропозиції. Наша історія триває, і ми завжди готові поділитися найкращим з вами. Наша молода команда завжди рада вітати нових членів. Якщо хочете працювати у нас, надсилайте резюме на пошту work@remartastyle.net або заповнюйте форму.
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					display="inline-block"
					width="150px"
					text-align="center"
					margin="20px 0px 0px 0px"
					type="link"
					text-decoration-line="initial"
					href="/contact"
					background="--color-orange"
				>
					Написати
				</Button>
			</Box>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80"
						width="320px"
						max-width="100%"
						srcSet="https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3200&q=80 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60"
						width="320px"
						max-width="100%"
						srcSet="https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60 500w,https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60 800w,https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1080&q=60 1080w,https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=60 1600w,https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=60 2000w,https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2600&q=60 2600w,https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3200&q=60 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80"
						width="320px"
						max-width="100%"
						srcSet="https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3200&q=80 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
	</Theme>;
});